export const isExternalLink = (href?: string) => {
  if (href === undefined) {
    return false;
  }
  const urlIsAbsolute = href.indexOf("http://") !== -1 || href?.indexOf("https://") !== -1;
  const isInternal = ["3t.no", "localhost", "3tno-frontend.vercel.app", "tttno-dev"].some((domain) =>
    href.includes(domain),
  );

  return urlIsAbsolute && !isInternal;
};
